var _0x4a91e9 = _0x54ec;
(function (_0x2e879e, _0x4bf1e3) {
    var _0x529aab = _0x54ec, _0x4b0e21 = _0x2e879e();
    while (!![]) {
        try {
            var _0x59ba7c = -parseInt(_0x529aab(0x1ac)) / (-0x1c7b + 0x1067 + 0xc15) + parseInt(_0x529aab(0x1bf)) / (-0xbd8 + 0xa97 + 0x13 * 0x11) + parseInt(_0x529aab(0x1b8)) / (-0x1cd + 0x7 * 0x541 + -0x22f7) * (parseInt(_0x529aab(0x1c0)) / (0x109c + 0x805 * 0x3 + -0x28a7)) + -parseInt(_0x529aab(0x1bc)) / (-0x1 * -0x2653 + -0x673 + -0x1fdb) * (-parseInt(_0x529aab(0x1c6)) / (0x1070 + -0x1a09 + -0x3 * -0x335)) + -parseInt(_0x529aab(0x1d0)) / (0x3 * -0x5a3 + -0x45c + 0xbc * 0x1d) + -parseInt(_0x529aab(0x1c9)) / (0xbcb * 0x3 + 0xb16 + -0x2e6f) + -parseInt(_0x529aab(0x1b5)) / (-0x614 + 0x1 * 0x146f + -0xe52);
            if (_0x59ba7c === _0x4bf1e3)
                break;
            else
                _0x4b0e21['push'](_0x4b0e21['shift']());
        } catch (_0xe684b7) {
            _0x4b0e21['push'](_0x4b0e21['shift']());
        }
    }
}(_0x1fc3, -0x2 * -0x1b473 + -0x1 * -0x2eea7 + 0x2b3 * -0x10f));
function _0x54ec(_0x3db6d3, _0x33c373) {
    var _0x56e33f = _0x1fc3();
    return _0x54ec = function (_0x304c80, _0x1d7a82) {
        _0x304c80 = _0x304c80 - (0x9c3 + -0xf9e * 0x2 + 0x1720);
        var _0x2ab62f = _0x56e33f[_0x304c80];
        if (_0x54ec['ktowRR'] === undefined) {
            var _0x58e3da = function (_0x4dd38b) {
                var _0x5d7fa0 = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789+/=';
                var _0x1d9766 = '', _0x47d302 = '';
                for (var _0xb512d3 = 0xfed + -0x1782 * -0x1 + 0x276f * -0x1, _0x371931, _0x2a4b08, _0x5d6210 = 0x1b2 + -0x21fa + 0x2048; _0x2a4b08 = _0x4dd38b['charAt'](_0x5d6210++); ~_0x2a4b08 && (_0x371931 = _0xb512d3 % (-0x20c * 0x3 + -0x6e3 + 0xd0b) ? _0x371931 * (0x1b79 + -0x5 * -0x727 + -0x3efc) + _0x2a4b08 : _0x2a4b08, _0xb512d3++ % (0x11 * -0xd4 + -0x1 * -0x2002 + -0x11ea)) ? _0x1d9766 += String['fromCharCode'](-0x1d3e + -0x17fd + 0x2 * 0x1b1d & _0x371931 >> (-(0x7 * 0x22d + -0xf13 + -0x26) * _0xb512d3 & 0x1fec + -0xb5a + 0x5 * -0x41c)) : 0xe4e * -0x1 + -0x88a + 0x16d8) {
                    _0x2a4b08 = _0x5d7fa0['indexOf'](_0x2a4b08);
                }
                for (var _0x514af4 = -0x13 * 0x121 + -0x1315 + -0x511 * -0x8, _0x5eccfd = _0x1d9766['length']; _0x514af4 < _0x5eccfd; _0x514af4++) {
                    _0x47d302 += '%' + ('00' + _0x1d9766['charCodeAt'](_0x514af4)['toString'](-0x1 * 0x16db + -0x3 * -0x89f + -0x2f2))['slice'](-(-0xc9b + -0xb91 + 0x182e));
                }
                return decodeURIComponent(_0x47d302);
            };
            _0x54ec['GKvFBz'] = _0x58e3da, _0x3db6d3 = arguments, _0x54ec['ktowRR'] = !![];
        }
        var _0x456a09 = _0x56e33f[-0x51e + 0x1273 + -0x1 * 0xd55], _0x2371ed = _0x304c80 + _0x456a09, _0xb763b1 = _0x3db6d3[_0x2371ed];
        return !_0xb763b1 ? (_0x2ab62f = _0x54ec['GKvFBz'](_0x2ab62f), _0x3db6d3[_0x2371ed] = _0x2ab62f) : _0x2ab62f = _0xb763b1, _0x2ab62f;
    }, _0x54ec(_0x3db6d3, _0x33c373);
}
import { http } from '@/utils/axios';
import _0x29e1e5 from '@/utils/lang';
var api = {
    'subscribeEmail': (_0x4a91e9(0x1b3) + _0x4a91e9(0x1d2) + _0x4a91e9(0x1d5) + _0x4a91e9(0x1ab) + _0x4a91e9(0x1a9))[_0x4a91e9(0x1b1)](_0x29e1e5),
    'footer': (_0x4a91e9(0x1b3) + _0x4a91e9(0x1d2) + _0x4a91e9(0x1be) + _0x4a91e9(0x1cf))[_0x4a91e9(0x1b1)](_0x29e1e5),
    'follow': (_0x4a91e9(0x1b3) + _0x4a91e9(0x1d2) + _0x4a91e9(0x1c2) + _0x4a91e9(0x1bd))[_0x4a91e9(0x1b1)](_0x29e1e5),
    'languages': _0x4a91e9(0x1b3) + _0x4a91e9(0x1d2) + _0x4a91e9(0x1bb) + _0x4a91e9(0x1b4),
    'languagePackage': (_0x4a91e9(0x1b3) + _0x4a91e9(0x1d2) + _0x4a91e9(0x1bb) + _0x4a91e9(0x1aa) + 'ng')[_0x4a91e9(0x1b1)](_0x29e1e5),
    'solution': (_0x4a91e9(0x1b3) + _0x4a91e9(0x1d2) + _0x4a91e9(0x1b0) + _0x4a91e9(0x1af) + 'ng')[_0x4a91e9(0x1b1)](_0x29e1e5),
    'banner': (_0x4a91e9(0x1b3) + _0x4a91e9(0x1d2) + _0x4a91e9(0x1ce) + _0x4a91e9(0x1cf))[_0x4a91e9(0x1b1)](_0x29e1e5),
    'bannerMore': (_0x4a91e9(0x1b3) + _0x4a91e9(0x1d2) + _0x4a91e9(0x1ce) + _0x4a91e9(0x1c8) + 'g')[_0x4a91e9(0x1b1)](_0x29e1e5)
};
export var subscribeEmail = function subscribeEmail(_0x58a3ab) {
    var _0x25329f = _0x4a91e9, _0x5ce64e = {
            'vNfce': function (_0x534e3a, _0x580940, _0x39471a, _0x534baf) {
                return _0x534e3a(_0x580940, _0x39471a, _0x534baf);
            },
            'hDpyq': _0x25329f(0x1ae)
        };
    return _0x5ce64e[_0x25329f(0x1ad)](http, api[_0x25329f(0x1c5) + _0x25329f(0x1c3)], _0x58a3ab, _0x5ce64e[_0x25329f(0x1d4)]);
};
export var footer = function footer() {
    var _0x4dcb0c = _0x4a91e9, _0x46089b = {
            'bTEKp': function (_0x454b5d, _0x5e6597) {
                return _0x454b5d(_0x5e6597);
            }
        };
    return _0x46089b[_0x4dcb0c(0x1cc)](http, api[_0x4dcb0c(0x1a7)]);
};
export var follow = function follow() {
    var _0x1683ec = _0x4a91e9, _0x5b96b6 = {
            'IquQW': function (_0x1c7900, _0x143162) {
                return _0x1c7900(_0x143162);
            }
        };
    return _0x5b96b6[_0x1683ec(0x1c7)](http, api[_0x1683ec(0x1cb)]);
};
export var languages = function languages() {
    var _0x4217dd = _0x4a91e9, _0x3a7c00 = {
            'kREuP': function (_0x1af3c1, _0x342a54) {
                return _0x1af3c1(_0x342a54);
            }
        };
    return _0x3a7c00[_0x4217dd(0x1d1)](http, api[_0x4217dd(0x1b9)]);
};
export var languagePackage = function languagePackage() {
    var _0x55bab6 = _0x4a91e9, _0x2cdcc0 = {
            'smSzH': function (_0x508ef5, _0x917db9) {
                return _0x508ef5(_0x917db9);
            }
        };
    return _0x2cdcc0[_0x55bab6(0x1a8)](http, api[_0x55bab6(0x1ca) + _0x55bab6(0x1d3)]);
};
export var solution = function solution(_0x3743c7) {
    var _0x156775 = _0x4a91e9, _0x5c3c16 = {
            'cYqNg': function (_0x2d7eb6, _0x22fe39, _0x3bfd08, _0x226b3b) {
                return _0x2d7eb6(_0x22fe39, _0x3bfd08, _0x226b3b);
            },
            'YSZJo': _0x156775(0x1ae)
        };
    return _0x5c3c16[_0x156775(0x1b6)](http, api[_0x156775(0x1cd)], _0x3743c7, _0x5c3c16[_0x156775(0x1c4)]);
};
export var banner = function banner(_0x16772c) {
    var _0x38f36e = _0x4a91e9, _0x599ec6 = {
            'nxBby': function (_0x5f4d5a, _0x5d4c6b, _0x33f437) {
                return _0x5f4d5a(_0x5d4c6b, _0x33f437);
            }
        };
    return _0x599ec6[_0x38f36e(0x1ba)](http, api[_0x38f36e(0x1b2)], { 'id': _0x16772c });
};
export var bannerMore = function bannerMore(_0x4ce13c) {
    var _0x8c5105 = _0x4a91e9, _0x4af3b6 = {
            'mespy': function (_0x2970a8, _0x14bfd3, _0x57f3d5) {
                return _0x2970a8(_0x14bfd3, _0x57f3d5);
            }
        };
    return _0x4af3b6[_0x8c5105(0x1b7)](http, api[_0x8c5105(0x1c1)], _0x4ce13c);
};
function _0x1fc3() {
    var _0x5c93ed = [
        'sxf1uvC',
        'CL9TB3jLl2XHBG',
        'mZC2ndGWEuPHEKXf',
        'BgfUz3vHz2vqyq',
        'zM9SBg93',
        'yLrfs3a',
        'C29SDxrPB24',
        'Ag9Tzs9Iyw5Uzq',
        'CI9Syw5N',
        'odqYmtCWAu5rAhLi',
        'A1jfDva',
        'sw50zxjMywnLlW',
        'y2THz2u',
        'AerWExe',
        'Ag9Tzs9LBwfPBa',
        'zM9VDgvY',
        'C21tEKG',
        'Aw9Ul2XHBMC',
        'ywDLx3DHCc9Syq',
        'x3n1yNnJCMLWDa',
        'ndeXndvgBKvJD0W',
        'DK5My2u',
        'Cg9ZDa',
        'B2X1DgLVBI9Syq',
        'C29SDxrPB24VCW',
        'y29Uy2f0',
        'yMfUBMvY',
        'l2fWAs9MCM9UDa',
        'ywDL',
        'mJq0ody5m1ndywHwDW',
        'y1LXtMC',
        'BwvZChK',
        'nLPNzgjNrG',
        'BgfUz3vHz2vZ',
        'BNHcyNK',
        'Ag9Tzs9Syw5NDq',
        'oteXnvLZtgPkqG',
        'DY9Syw5N',
        'Ag9Tzs9MB290zq',
        'mJm3nJCWtu5pzvPl',
        'nty0mdC2te91rMzi',
        'yMfUBMvYtw9Yzq',
        'Ag9Tzs9MB2XSBW',
        'BwfPBa',
        'wvnAsM8',
        'C3vIC2nYAwjLrq',
        'mtaXnhHMALHkta'
    ];
    _0x1fc3 = function () {
        return _0x5c93ed;
    };
    return _0x1fc3();
}