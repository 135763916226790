var _0x32f001 = _0xca76;
function _0x4334() {
    var _0x2328dc = [
        'ntC4otyZmgDkEhvvyG',
        't2XTzKu',
        'Ag9TzvbHz2u',
        'CI9Syw5N',
        'ndG2mtyYz1LXzNfk',
        'ouT5sMDXuW',
        'vLHdBvC',
        'mte0mdqYvu5mtwvm',
        'l2fWAs9MCM9UDa',
        'mtGYmJGYn0vPtM9ttq',
        'Ag9Tzs9OB21LxW',
        'CgfNzs9Syw5N',
        'Ag9Tzs9PCc9Syq',
        'sw50zxjMywnLlW',
        'y29Uy2f0',
        'mte4mZi4nZjcv2DNs2m',
        'mJm1EuLHz1by',
        'Axbmyw5N',
        'ChnZsfq',
        'mZG1mtyYsNL0rfPL',
        'mJuYndi2ngTiyLzLEa',
        'AgvHzgvY',
        'Ag9Tzs9OzwfKzq'
    ];
    _0x4334 = function () {
        return _0x2328dc;
    };
    return _0x4334();
}
(function (_0xa4445f, _0x28be8d) {
    var _0x2b6166 = _0xca76, _0x12961e = _0xa4445f();
    while (!![]) {
        try {
            var _0x38b8bc = parseInt(_0x2b6166(0xa7)) / (0x1dcb + -0x3 * 0x239 + -0x171f) + parseInt(_0x2b6166(0x9f)) / (0x20e0 + 0xb76 + -0x2c54) + -parseInt(_0x2b6166(0xac)) / (-0xd7c * -0x2 + 0xd * 0x15b + -0x4f4 * 0x9) + parseInt(_0x2b6166(0xa0)) / (-0x229d + 0x1efc + 0x3a5) + -parseInt(_0x2b6166(0x9c)) / (0x7e * 0x2e + -0x496 + 0x1b * -0xab) * (parseInt(_0x2b6166(0xaa)) / (0x3 * 0x8e1 + 0x1544 + -0x1 * 0x2fe1)) + -parseInt(_0x2b6166(0xa3)) / (-0xac * 0x8 + -0xa31 * 0x2 + -0x1 * -0x19c9) + parseInt(_0x2b6166(0x9b)) / (-0x709 * -0x4 + 0x17f5 + -0x3411) * (parseInt(_0x2b6166(0xa8)) / (0x6d + 0x9e * 0x9 + -0x5f2));
            if (_0x38b8bc === _0x28be8d)
                break;
            else
                _0x12961e['push'](_0x12961e['shift']());
        } catch (_0x542eb8) {
            _0x12961e['push'](_0x12961e['shift']());
        }
    }
}(_0x4334, 0x331fc + 0x2baa * 0xd + 0x19ebc));
import { http } from '@/utils/axios';
function _0xca76(_0x7afd88, _0x373584) {
    var _0xf968f1 = _0x4334();
    return _0xca76 = function (_0x481fe4, _0x19f810) {
        _0x481fe4 = _0x481fe4 - (-0x5ae * -0x3 + 0x3 * -0x557 + -0x1 * 0x6c);
        var _0x516efe = _0xf968f1[_0x481fe4];
        if (_0xca76['iiBMxc'] === undefined) {
            var _0x47741f = function (_0x576014) {
                var _0x811903 = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789+/=';
                var _0x2ae1a4 = '', _0x2d5eeb = '';
                for (var _0x3f74b8 = -0x1dca + -0x7 * -0x443 + -0xb, _0x6003, _0xb607da, _0x4b4be9 = -0x2 * 0x12fd + 0x115b + 0x149f * 0x1; _0xb607da = _0x576014['charAt'](_0x4b4be9++); ~_0xb607da && (_0x6003 = _0x3f74b8 % (-0x1f * -0x1a + -0x1507 + 0x1fd * 0x9) ? _0x6003 * (0x1 * 0x11fd + 0x14a3 + -0x2660) + _0xb607da : _0xb607da, _0x3f74b8++ % (0x2c2 * -0x4 + -0xb59 + 0x1665)) ? _0x2ae1a4 += String['fromCharCode'](0x1b7 * 0x8 + 0x7 * -0xe + -0xc57 & _0x6003 >> (-(0x166f + -0x156c + -0x101 * 0x1) * _0x3f74b8 & -0x29 * 0xd6 + 0x1987 + 0x8c5)) : -0x2 * -0x1061 + 0x1 * 0xbb3 + -0x2c75) {
                    _0xb607da = _0x811903['indexOf'](_0xb607da);
                }
                for (var _0x5b10d4 = 0x1abf + -0x25dc + 0xb1d, _0x55ec5f = _0x2ae1a4['length']; _0x5b10d4 < _0x55ec5f; _0x5b10d4++) {
                    _0x2d5eeb += '%' + ('00' + _0x2ae1a4['charCodeAt'](_0x5b10d4)['toString'](-0x3 * 0x4c3 + -0xc93 + 0x6bb * 0x4))['slice'](-(-0x1d97 + 0x2575 + -0x7dc));
                }
                return decodeURIComponent(_0x2d5eeb);
            };
            _0xca76['evywoB'] = _0x47741f, _0x7afd88 = arguments, _0xca76['iiBMxc'] = !![];
        }
        var _0x13f62c = _0xf968f1[0xdba + 0xde0 + 0x1b9a * -0x1], _0x3d8f5b = _0x481fe4 + _0x13f62c, _0x278732 = _0x7afd88[_0x3d8f5b];
        return !_0x278732 ? (_0x516efe = _0xca76['evywoB'](_0x516efe), _0x7afd88[_0x3d8f5b] = _0x516efe) : _0x516efe = _0x278732, _0x516efe;
    }, _0xca76(_0x7afd88, _0x373584);
}
import _0x2f8a92 from '@/utils/lang';
var api = {
    'header': (_0x32f001(0xab) + _0x32f001(0x99) + _0x32f001(0xa2) + _0x32f001(0xa6))[_0x32f001(0x9a)](_0x2f8a92),
    'homePage': (_0x32f001(0xab) + _0x32f001(0x99) + _0x32f001(0xad) + _0x32f001(0xae))[_0x32f001(0x9a)](_0x2f8a92),
    'ipLang': (_0x32f001(0xab) + _0x32f001(0x99) + _0x32f001(0xaf) + 'ng')[_0x32f001(0x9a)](_0x2f8a92)
};
export var header = function header() {
    var _0x34c5a0 = _0x32f001, _0x5db2d3 = {
            'VXCmW': function (_0x4ed80d, _0x5094b6) {
                return _0x4ed80d(_0x5094b6);
            }
        };
    return _0x5db2d3[_0x34c5a0(0xa9)](http, api[_0x34c5a0(0xa1)]);
};
export var homePage = function homePage() {
    var _0x4d43e5 = _0x32f001, _0x213050 = {
            'pssHT': function (_0x23e9b1, _0x6fb052) {
                return _0x23e9b1(_0x6fb052);
            }
        };
    return _0x213050[_0x4d43e5(0x9e)](http, api[_0x4d43e5(0xa5)]);
};
export var ipLang = function ipLang() {
    var _0x4dd2aa = _0x32f001, _0x57ab1c = {
            'OlmfE': function (_0x3df7fc, _0x32e02a) {
                return _0x3df7fc(_0x32e02a);
            }
        };
    return _0x57ab1c[_0x4dd2aa(0xa4)](http, api[_0x4dd2aa(0x9d)]);
};